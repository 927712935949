import React, { useRef } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import calculateElementOnBasis from "../utils/calculateElementOnBasis";
import { useNavigate } from "react-router-dom";

const React301bt = () => {
  const windowDimensions = useWindowDimensions();
  const navigate = useNavigate();
  const coursePlanRef = useRef(null);

  return (
    <>
      <div className="w-full">
        <img
          className="w-full"
          alt="wucareer 課程"
          src={`${process.env.REACT_APP_STATIC_HOST}/static/react301bt.png`}
        />
        <div
          id="cta"
          style={calculateElementOnBasis(
            375,
            248,
            45,
            295,
            65,
            windowDimensions.width
          )}
          onClick={() => {
            coursePlanRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }}
        ></div>

        <div
          id="plan"
          style={calculateElementOnBasis(
            375,
            335,
            100,
            4120,
            20,
            windowDimensions.width
          )}
          ref={coursePlanRef}
        ></div>

        <div
          id="card-1"
          style={calculateElementOnBasis(
            375,
            335,
            870,
            4236,
            20,
            windowDimensions.width
          )}
          onClick={() => {
            navigate("/redirect/checkout", {
              state: {
                to: "https://core.newebpay.com/EPG/wucareer/2EVTYx",
                productName: "初階企業電商作品陪跑課",
              },
            });
          }}
        ></div>

        <div
          id="card-2"
          style={calculateElementOnBasis(
            375,
            335,
            280,
            5135,
            20,
            windowDimensions.width
          )}
          onClick={() => {
            navigate("/courses/react301");
          }}
        ></div>
      </div>
    </>
  );
};

export default React301bt;
