import React, { useRef } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import calculateElementOnBasis from "../utils/calculateElementOnBasis";
import { useNavigate } from "react-router-dom";

const Dev101 = () => {
  const windowDimensions = useWindowDimensions();
  const navigate = useNavigate();
  const coursePlanRef = useRef(null);

  return (
    <>
      <div className="w-full">
        <img
          className="w-full"
          alt="wucareer 課程"
          src={`${process.env.REACT_APP_STATIC_HOST}/static/dev101.png`}
        />
        <div
          id="cta"
          style={calculateElementOnBasis(
            375,
            243,
            45,
            295,
            65,
            windowDimensions.width
          )}
          onClick={() => {
            coursePlanRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }}
        ></div>

        <div
          id="plan"
          style={calculateElementOnBasis(
            375,
            335,
            100,
            3200,
            20,
            windowDimensions.width
          )}
          ref={coursePlanRef}
        ></div>

        <div
          id="card-1"
          style={calculateElementOnBasis(
            375,
            335,
            645,
            3315,
            20,
            windowDimensions.width
          )}
          onClick={() => {
            navigate("/redirect/freebee", {
              state: {
                to: "https://lin.ee/NskkhxU",
                productName: "Junior 前端職涯必修課程",
                passcode: "dev1688",
              },
            });
          }}
        ></div>

        <div
          id="card-2"
          style={calculateElementOnBasis(
            375,
            335,
            715,
            3978,
            20,
            windowDimensions.width
          )}
          onClick={() => {
            navigate("/redirect/checkout", {
              state: {
                to: "https://core.newebpay.com/EPG/MS3537469802/voWDNn",
                productName: "溫攀 50 分鐘 1v1 業師諮詢",
              },
            });
          }}
        ></div>
      </div>
    </>
  );
};

export default Dev101;
