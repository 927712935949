const calculateElementOnBasis = (
  basisScreenW,
  basisElW,
  basisElH,
  basisTop,
  basisLeft,
  targetScreenW,
  debug = false
) => {
  const increaseRatio = (targetScreenW - basisScreenW) / basisScreenW;
  const targetElW = basisElW * (1 + increaseRatio);
  const targetElH = basisElH * (1 + increaseRatio);
  const targetTop = basisTop * (1 + increaseRatio);
  const targetLeft = basisLeft * (1 + increaseRatio);

  if (debug !== true) {
    return {
      position: "absolute",
      width: `${targetElW}px`,
      height: `${targetElH}px`,
      top: `${targetTop}px`,
      left: `${targetLeft}px`,
    };
  }

  return {
    position: "absolute",
    border: "1px solid red",
    width: `${targetElW}px`,
    height: `${targetElH}px`,
    top: `${targetTop}px`,
    left: `${targetLeft}px`,
  };
};

export default calculateElementOnBasis;
