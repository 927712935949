import React from "react";
import { useLocation } from "react-router-dom";

const FreebeeBlocker = () => {
  const location = useLocation();
  const { to, productName, passcode } = location.state || {};

  return (
    <>
      <div className="mt-[60px] 2xs:p-[20px] md:w-1/2 md:mx-auto lg:w-1/5">
        <img
          className="w-full"
          src="https://qr-official.line.me/gs/M_752emhwu_GW.png?oat_content=qr"
        ></img>
        <div className="text-center">
          <p className="pb-2">
            您正準備領取「{productName || null}」此為免費課程，需要請您在
            WuCareer 官方 Line 輸入通關密語{" "}
            <span className="font-bold">「{passcode || null}」</span>
            ，領取免費課程
          </p>
          <p className="mb-6">
            如果尚未加入 WuCareer 官方 Line，可以透過上方 QR Code
            掃描加入，或是搜尋 Line ID:
            <span
              className="font-bold underline"
              onClick={() => {
                window.open("https://lin.ee/NskkhxU", "_blank");
              }}
            >
              @752emhwu
            </span>{" "}
            加入
          </p>
          <div
            onClick={() => {
              window.open(to, "_blank");
            }}
            className="w-full py-[12px] rounded-[999px] text-white bg-gradient-to-r from-[#3630EC] to-[#C8379D]"
          >
            我知道了，帶我到 WuCareer 官方 Line
          </div>
        </div>
      </div>
    </>
  );
};

export default FreebeeBlocker;
