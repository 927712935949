import React from "react";
import Dev101 from "../pages/Dev101.page";
import React201 from "../pages/React201.page";
import React301 from "../pages/React301.page";
import React301bt from "../pages/React301bt.page";
import { createBrowserRouter } from "react-router-dom";
import FreebeeBlocker from "../components/FreebeeBlocker.component";
import CheckoutBlocker from "../components/CheckoutBlocker.component";

const router = createBrowserRouter([
  {
    path: "/redirect",
    children: [
      {
        path: "freebee",
        element: <FreebeeBlocker />,
      },
      {
        path: "checkout",
        element: <CheckoutBlocker />,
      },
    ],
  },
  {
    path: "/courses",
    children: [
      {
        path: "dev101",
        element: <Dev101 />,
      },
      {
        path: "react201",
        element: <React201 ctaLink={"https://forms.gle/gGDbwssn8qdkDB8q6"} />,
      },
      {
        path: "react301",
        element: <React301 />,
      },
      {
        path: "react301bt",
        element: <React301bt />,
      },
    ],
  },
  {
    path: "/affliate/courses",
    children: [
      {
        path: "react201",
        element: <React201 ctaLink={"https://forms.gle/wRK9AZ39L6HQbZxU9"} />,
      },
    ],
  },
]);

export default router;
