/* eslint-disable react/react-in-jsx-scope */
import { useRef } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import calculateElementOnBasis from "../utils/calculateElementOnBasis";
import { Link } from "react-router-dom";

const React201 = ({ ctaLink }) => {
  const windowDimensions = useWindowDimensions();
  const coursePlanRef = useRef(null);

  return (
    <>
      <div className="w-full">
        <img
          className="w-full"
          alt="wucareer 課程"
          src={`${process.env.REACT_APP_STATIC_HOST}/static/react201.png`}
        />
        <div
          id="cta"
          style={calculateElementOnBasis(
            375,
            315,
            45,
            295,
            30,
            windowDimensions.width
          )}
          onClick={() => {
            coursePlanRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }}
        ></div>

        <div
          id="plan"
          style={calculateElementOnBasis(
            375,
            335,
            100,
            3790,
            20,
            windowDimensions.width
          )}
          ref={coursePlanRef}
        ></div>

        <Link
          id="card-1"
          style={calculateElementOnBasis(
            375,
            335,
            690,
            3905,
            20,
            windowDimensions.width
          )}
          to={ctaLink}
        ></Link>
      </div>
    </>
  );
};

export default React201;
