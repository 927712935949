import React from "react";
import { useLocation } from "react-router-dom";

const CheckoutBlocker = () => {
  const location = useLocation();
  const { to, productName } = location.state || {};
  console.log(location);

  return (
    <>
      <div className="mt-[60px] 2xs:p-[20px] md:w-1/2 md:mx-auto lg:w-1/5">
        <img
          className="w-full"
          src="https://qr-official.line.me/gs/M_752emhwu_GW.png?oat_content=qr"
        ></img>
        <div className="text-center">
          <p className="pb-2">
            您正在跳轉到「{productName || null}」的付款頁面...
            提醒您，在付款完成後需要請你將
            <span className="font-bold">付款完成的截圖</span>，及
            <span className="font-bold">付款人的資訊</span>回傳至 WuCareer 官方
            Line 由小助手確認付款是否成功，並為您安排後續開課事宜。
          </p>
          <p className="mb-6">
            如果尚未加入 WuCareer 可以透過上方 QR Code 掃描加入，或是搜尋 Line
            ID:{" "}
            <span
              className="font-bold underline"
              onClick={() => {
                window.open("https://lin.ee/NskkhxU", "_blank");
              }}
            >
              @752emhwu
            </span>
          </p>
          <div
            onClick={() => {
              window.open(to, "_blank");
            }}
            className="w-full py-[12px] rounded-[999px] text-white bg-gradient-to-r from-[#3630EC] to-[#C8379D]"
          >
            我知道了，帶我到付款頁面
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutBlocker;
